import React from 'react';
import JotformEmbed from 'react-jotform-embed';

class IContactSupport extends React.Component {
  render() {
    return (
      <div className="container">
        <JotformEmbed src="https://lab.jotform.com/193444310266151" />
      </div>
    );
  }
}

export default IContactSupport;
