import React from 'react';
import IContactSupport from '../../components/contact/contact-support';
import IFUHomeBtn from '../../components/ifu-home-btn/ifu-home-btn';

export default () => {
  return (
    <>
      <IFUHomeBtn />
      <IContactSupport />
    </>
  );
};
